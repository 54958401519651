import React, { useEffect, useState } from "react";
import "./App.css";
import "./App-dark.css";

import Auris from "./Images/Auris.png";
import ComputerTalk from "./Images/ComputerTalk.png";
import Encyclopedia from "./Images/Encyclopedia.png";
import EmojiGuessingGame from "./Images/EmojiGuessingGame.png";
import ImageResizer from "./Images/ImageResizer.png";
import JobAdvisor from "./Images/JobAdvisor.png";
import Notaduo from "./Images/Notaduo.png";
import PWGenerator from "./Images/PWGenerator.png";
import QrGenerator from "./Images/QrGenerator.png";
import SumaNews from "./Images/SumaNews.png";
import TextCounter from "./Images/TextCounter.png";

import PaperAirplane from "./Images/PaperAirplane.png";

function App() {
	const isTouchDevice = "ontouchstart" in window;
	const [imageUrls, setImageUrls] = useState([
		{ url: ComputerTalk, link: "https://computertalk.netlify.app", text: "Computer Talk", overlayShown: false },
		{ url: Encyclopedia, link: "https://encyclopedia.one/", text: "Encyclopedia", overlayShown: false },
		{ url: EmojiGuessingGame, link: "https://emojiguessinggame.netlify.app", text: "Guessing Game", overlayShown: false },
		{ url: JobAdvisor, link: "https://jobadvisor.one/", text: "Job Advisor", overlayShown: false },
		{ url: Auris, link: "https://auriseartraining.com/", text: "Auris Ear Training", overlayShown: false },
		{ url: Notaduo, link: "https://notaduo.com/", text: "Notaduo", overlayShown: false },
		{ url: ImageResizer, link: "https://imageresizertool.netlify.app", text: "Image Resizer", overlayShown: false },
		{ url: PWGenerator, link: "https://pwgeneratortool.netlify.app", text: "Password Generator", overlayShown: false },
		{ url: QrGenerator, link: "https://qrgeneratortool.netlify.app", text: "QR Generator", overlayShown: false },
		{ url: SumaNews, link: "https://sumanews.netlify.app", text: "Suma News", overlayShown: false },
		{ url: TextCounter, link: "https://textcountertool.netlify.app", text: "Text Counter", overlayShown: false },
	]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 9;
	const totalPages = Math.ceil(imageUrls.length / itemsPerPage);
	const currentImages = imageUrls.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

	const handlePageChange = (direction) => {
		if (direction === ">") {
			setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
		} else if (direction === "<") {
			setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
		}
	};

	useEffect(() => {
		const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
		const handleDarkModeChange = (e) => {
			document.body.classList.toggle("dark", e.matches);
		};
		darkModeMediaQuery.addEventListener("change", handleDarkModeChange);

		handleDarkModeChange(darkModeMediaQuery);

		return () => {
			darkModeMediaQuery.removeEventListener("change", handleDarkModeChange);
		};
	}, []);

	const handleImageClick = (e, image, index) => {
		e.preventDefault();
		const absoluteIndex = (currentPage - 1) * itemsPerPage + index;
		const updatedImages = imageUrls.map((img, idx) => {
			if (idx === absoluteIndex) {
				return { ...img, overlayShown: !img.overlayShown };
			}
			return img;
		});

		setImageUrls(updatedImages);

		if (image.overlayShown) {
			window.location.href = image.link;
		}
	};

	const handleMouseEnter = (pageRelativeIndex) => {
		if (!isTouchDevice) {
			const absoluteIndex = (currentPage - 1) * itemsPerPage + pageRelativeIndex;
			setImageUrls((prevImageUrls) =>
				prevImageUrls.map((img, idx) => (idx === absoluteIndex ? { ...img, overlayShown: true } : img))
			);
		}
	};

	const handleMouseLeave = (pageRelativeIndex) => {
		if (!isTouchDevice) {
			const absoluteIndex = (currentPage - 1) * itemsPerPage + pageRelativeIndex;
			setImageUrls((prevImageUrls) =>
				prevImageUrls.map((img, idx) => (idx === absoluteIndex ? { ...img, overlayShown: false } : img))
			);
		}
	};

	return (
		<div className="App">
			<header>
				<br />
				<br />
				<h1>OCTO OCTO</h1>
			</header>
			<main>
				<div>
					<br />
					{/* <br /> */}
					<div className="image-container">
						{currentImages.map((image, index) => (
							<div
								key={index}
								onMouseEnter={() => (isTouchDevice ? null : handleMouseEnter(index))}
								onMouseLeave={() => (isTouchDevice ? null : handleMouseLeave(index))}
							>
								<a
									href={image.link}
									onClick={(e) => handleImageClick(e, image, index)}
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={image.url} alt={image.text} />
									{image.overlayShown && (
										<div className="overlay">
											<span className="overlay-text">{image.text}</span>
										</div>
									)}
								</a>
							</div>
						))}
					</div>

					<br />

					{currentPage > 1 && (
						<button className="pagination-button" onClick={() => handlePageChange("<")}>
							{"←"}
						</button>
					)}
					{currentPage < totalPages && (
						<button className="pagination-button" onClick={() => handlePageChange(">")}>
							{"→"}
						</button>
					)}
				</div>
				<div className="octo-octo-logo">
					<a href="mailto:info@auriseartraining.com?subject=Hello&body=Message">
						<img src={PaperAirplane} alt="contact"></img>
					</a>
				</div>
			</main>
		</div>
	);
}

export default App;
